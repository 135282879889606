import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  GET_BUDGET_SPENDING_VALUE_LIST,
  GET_BUDGET_SPENDING_LIST,
  GET_BUDGET_SPENDING_PURCHASE_DOCUMENT,
  GET_BUDGET_DASHBOARD,
} from 'shared/constants/ActionTypes';
import {appIntl} from '@main/utility/helper/Utils';
import fmAxios from '../../@main/services/auth/fm-auth/index';

export const onGetBudgetSpendingValueList = () => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(`/layouts/common/script/budgetsValueLists?`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_BUDGET_SPENDING_VALUE_LIST,
            payload: data.data.response.scriptResult,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onBudgetSpending = (location) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/purchaseDocuments/script/onBudgetSpending?&script.param=${location}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_BUDGET_SPENDING_LIST,
            payload: data.data.response.scriptResult,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onGetSelectedItem = (id) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});
    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };
    fmAxios
      .get(`/layouts/purchaseDocuments/records/${id}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_BUDGET_SPENDING_PURCHASE_DOCUMENT,
            payload: data.data['response'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateSelectedItem = (id, request, location) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});
    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };
    fmAxios
      .patch(`/layouts/purchaseDocuments/records/${id}`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );

          fmAxios
            .get(
              `/layouts/purchaseDocuments/script/onBudgetSpending?&script.param=${location}`,
            )
            .then((data) => {
              if (data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                sessionStorage.setItem(
                  'auth-token-timestamp',
                  JSON.stringify(new Date()),
                );
                dispatch({
                  type: GET_BUDGET_SPENDING_LIST,
                  payload: data.data.response.scriptResult,
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Údaje boli uložené',
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: messages['message.somethingWentWrong'],
                });
              }
            });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onBudgetDashBoard = (budget) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/purchaseDocuments/script/onBudgetDashBoard?&script.param=${budget}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_BUDGET_DASHBOARD,
            payload: data.data.response.scriptResult,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
