//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const SHOW_INFO = 'show_info';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

//NOTIFICATIONS
export const GET_NOTIFICATIONS_LIST = 'get_notifications_list';

//BUDGET_DASHBOARD
export const GET_BUDGET_DASHBOARD = 'get_budget_dashboard';

//MEMBER_PAYMENTS_DASHBOARD
export const MEMBER_PAYMENTS = 'member_payments';

//INFOPOINT_DASHBOARD
export const GET_INFOPOINT_DASHBORD = 'get_infopoint_dashboard';

//STATISTIC_DASHBOARD
export const GET_STATISTIC_DASHBOARD = 'get_statistic_dashboard';

//PARTENRS
export const GET_PARTERS_LIST = 'get_customer_list';
export const GET_PARTNER_DETAIL = 'get_partner_detail';
export const GET_PARTNER_FILTER = 'get_partner_filter';
export const GET_PARTNER_VALUE_LIST = 'get_partner_type_list';
export const UPDATE_PARTNER_DETAIL = 'update_partner_detail';
export const DATA_HUB_PARTNER = 'data_hub_partner';

//ORDERS

export const GET_ORDERS_LIST = 'get_orders_list';
export const GET_ORDER_DETAIL = 'get_order_detail';
export const GET_ORDER_FILTER = 'get_order_filter';
export const GET_ORDER_VALUE_LIST = 'get_order_value_list';
export const UPDATE_ORDER_DETAIL = 'update_order_detail';

//BUDGET SPENDING
export const GET_BUDGET_SPENDING_VALUE_LIST = 'get_budget_spending_value_list';
export const GET_BUDGET_SPENDING_LIST = 'get_budget_spending_list';
export const UPDATE_BUDGET_SPENDING_LIST = 'update_budget_spending_list';
export const GET_BUDGET_SPENDING_PURCHASE_DOCUMENT =
  'get_budget_spending_purchase_document';

//INFOPOINT
export const GET_ATTENDANCE_LIST = 'get_attendance_list';
export const GET_ATTENDANCE_VALUE_LIST = 'get_attendance_value_list';

//RECEIVED DOCUMENTS
export const GET_RECEIVED_DOCUMENTS_LIST = 'get_received_documents_list';
export const GET_RECEIVED_DOCUMENTS_TRIP_LIST =
  'get_received_documents_trip_list';
export const UPDATE_RECEIVED_DOCUMENTS_LIST = 'update_received_documents_list';
export const GET_RECEIVED_DOCUMENTS_DETAIL = 'get_received_documents_detail';
export const UPDATE_RECEIVED_DOCUMENTS_DETAIL =
  'update_received_documents_detail';
export const UPDATE_RECEIVED_DOCUMENTS_TRIP_DETAIL =
  'update_received_documents_trip_detail';
export const GET_BANK_ITEMS = 'get_bank_items';

//EXPERIENCES
export const GET_EXPERIENCES_LIST = 'get_experiences_list';
export const GET_LOCALITY_LIST = 'get_locality_list';

//EVENTS
export const GET_EVENTS_LIST = 'get_events_list';
export const GET_EVENT_DETAIL = 'get_event_detail';
export const UPDATE_EVENT_DETAIL = 'update_event_detail';
export const GET_EVENTS_VALUE_LIST = 'get_events_value_list';

//PAPAYA
export const GET_STOCK_ITEMS_LIST = 'get_stock_items_list';
export const ADD_TO_STOCK_ITEMS_LIST = 'add_to_stock_items_list';
export const GET_STOCK_ITEM_DETAIL = 'get_stock_item_detail';
export const GET_STOCK_ITEM_VALUE_LIST = 'get_stock_item_value_list';
export const UPDATE_STOCK_ITEM = 'update_stock_item';
export const GET_STOCK_ITEM_MOVEMENTS = 'get_stock_item_movements';
