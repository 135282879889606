import React from 'react';
import {Navigate} from 'react-router-dom';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const Partners = React.lazy(() => import('./Partners'));
const Orders = React.lazy(() => import('./Orders'));
const BudgetSpending = React.lazy(() => import('./BudgetSpending'));
//const Attendance = React.lazy(() => import('./Attendance'));
const AttendanceTest = React.lazy(() => import('./Attendance/indexTest'));
const KDP = React.lazy(() => import('./KDP'));
const ReceivedDocuments = React.lazy(() => import('./ReceivedDocuments'));
const Experiences = React.lazy(() => import('./Experiences'));
const Events = React.lazy(() => import('./Events'));
const PapayaStockItems = React.lazy(() => import('./PapayaStockItems'));
const PapayaStockMovements = React.lazy(() => import('./PapayaStockMovements'));

export const appConfigs = [
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
    path: ['/app/partners/:folder/:id', '/app/partners/:folder'],
    element: <Partners />,
  },

  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
    path: '/app/partners',
    element: <Navigate to='/app/partners/all' />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
    path: [
      '/app/orders/:date/:circle/:status/:budget/:id',
      '/app/orders/:date/:circle/:status/:budget',
    ],
    element: <Orders />,
  },

  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
    path: '/app/orders',
    element: <Navigate to={`/app/orders/all/all/all/all`} />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
    path: [
      '/app/budget-spending/:budget/:section/:activity/:id',
      '/app/budget-spending/:budget/:section/:activity',
    ],
    element: <BudgetSpending />,
  },

  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
    path: '/app/budget-spending',
    element: <Navigate to={`/app/budget-spending/nezaradene/all/all`} />,
  },

  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.User,
      RoutePermittedRole.Infopoint,
    ],
    path: ['/app/infopoint/attendanceTest'],
    element: <AttendanceTest />,
  },

  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.User,
      RoutePermittedRole.Infopoint,
    ],
    path: ['/app/infopoint/kdp'],
    element: <KDP />,
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.User,
      RoutePermittedRole.Accountant,
    ],
    path: ['/app/receivedDocuments/:id', '/app/receivedDocuments'],
    element: <ReceivedDocuments />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/app/experiences'],
    element: <Experiences />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
    path: ['/app/events/:id', '/app/events'],
    element: <Events />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/app/papayaStockItems/:id', '/app/papayaStockItems'],
    element: <PapayaStockItems />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/app/PapayaStockMovements'],
    element: <PapayaStockMovements />,
  },
];
