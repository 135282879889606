import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_RECEIVED_DOCUMENTS_LIST,
  GET_RECEIVED_DOCUMENTS_TRIP_LIST,
  GET_RECEIVED_DOCUMENTS_DETAIL,
  UPDATE_RECEIVED_DOCUMENTS_DETAIL,
  UPDATE_RECEIVED_DOCUMENTS_LIST,
  UPDATE_RECEIVED_DOCUMENTS_TRIP_DETAIL,
  GET_BANK_ITEMS,
} from 'shared/constants/ActionTypes';
import {appIntl} from '@main/utility/helper/Utils';
import fmAxios from '../../@main/services/auth/fm-auth/index';

export const onGetReceivedDocumentsList = (request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .post(`/layouts/receivedDocuments/_find`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_RECEIVED_DOCUMENTS_LIST,
            payload: data.data['response'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        if (error['response'].data.messages[0].code === '401') {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          var emtyPayload = {
            dataInfo: {
              foundCount: parseInt(0),
            },
            data: [],
            noRecordsFound: true,
          };
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_RECEIVED_DOCUMENTS_LIST, payload: emtyPayload});
        } else {
          dispatch({type: FETCH_ERROR, payload: error.message});
        }
      });
  };
};

export const onGetReceivedDocumentDetail = (id) => {
  return (dispatch) => {
    const {messages} = appIntl();
    // dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(
        `/layouts/purchaseDocuments/script/getItemDetail?&script.param=${id}`,
      )
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_RECEIVED_DOCUMENTS_DETAIL,
            payload: data.data.response.scriptResult,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateReceivedDocumentDetail = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_RECEIVED_DOCUMENTS_DETAIL, payload: data});
  };
};

export const onUpdateReceivedDocumentTripDetail = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_RECEIVED_DOCUMENTS_TRIP_DETAIL, payload: data});
  };
};

export const onUpdateReceivedDocumentList = (idList) => {
  return (dispatch) => {
    dispatch({type: UPDATE_RECEIVED_DOCUMENTS_LIST, payload: idList});
  };
};

export const onGetReceivedDocumentsTripList = (request) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .post(`/layouts/receivedDocuments/_find`, request)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_RECEIVED_DOCUMENTS_TRIP_LIST,
            payload: data.data['response'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        if (error['response'].data.messages[0].code === '401') {
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          var emtyPayload = {
            dataInfo: {
              foundCount: parseInt(0),
            },
            data: [],
            noRecordsFound: true,
          };
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_RECEIVED_DOCUMENTS_TRIP_LIST,
            payload: emtyPayload,
          });
        } else {
          dispatch({type: FETCH_ERROR, payload: error.message});
        }
      });
  };
};

export const onGetBankItem = () => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});

    fmAxios.defaults.headers.common = {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
    };

    fmAxios
      .get(`/layouts/common/script/onBankItem?`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          sessionStorage.setItem(
            'auth-token-timestamp',
            JSON.stringify(new Date()),
          );
          dispatch({
            type: GET_BANK_ITEMS,
            payload: data.data.response.scriptResult,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
